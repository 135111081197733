import React from 'react';

import {
    HeroBanner,
    CaseHeader,
    Carousel,
    DeviceType,
    Divider,
    Testimonial,
    TextContent,
    Gallery,
    GalleryLayout,
    Team,
} from 'modules/references';
import { sendCaseStudyPageView } from 'utils/analytics';

import Seo from '../../components/SEO';

import socialPreview from '../../../static/social-previews/flashsport.png';

const [loadingVideo, previewVideo, notifyVideo] = ['loading', 'preview', 'notif'].map(
    filename => `https://storage.googleapis.com/ackee-web-static/videos/case-studies/flashsport/${filename}.mp4`,
);

const team = [
    { department: 'references.case.team.management', names: ['Boris Kováč', 'Patrik Šonský'] },
    { department: 'references.case.team.design', names: ['Roman Gordienko', 'Michal Mikolaj'] },
    {
        department: 'references.case.team.backend',
        names: ['Antonín Křivohlavý', 'Jaroslav Šmolík', 'Jiří Šmolík', 'Michal Vlasák', 'Zhanybek Sadvakassov'],
    },
    { department: 'references.case.team.web', names: ['Lukáš Horák', 'Marek Janča', 'Jiří Zdvomka'] },
    {
        department: 'references.case.team.android',
        names: ['David Bilík', 'Michal Pešek'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Igor Rosocha', 'Jakub Olejník'],
    },
    {
        department: 'references.case.team.devops',
        names: ['Sandra Tatarevićová', 'Tomáš Hejátko'],
    },
    { department: 'references.case.team.testing', names: ['Markéta Hejná', 'Julie Klikarová'] },
];

const FlashSport = () => (
    <>
        <Seo image={socialPreview} pageId="flashsport" handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            headerTheme="dark"
            content={{
                projectName: 'case.flashsport.hero.project',
                projectTitle: 'case.flashsport.hero.title',
                projectYear: 'case.flashsport.hero.year',
                client: 'case.flashsport.hero.client',
                image: {
                    src: 'references/case-studies/flashsport/hero_device.png',
                    style: { height: '100%', width: '100%', marginTop: '3rem' },
                },
            }}
            backgroundImage={{
                src: 'references/case-studies/flashsport/bg.png',
                imgStyle: { objectFit: 'cover', objectPosition: 'center 20%' },
            }}
            background={'#F5F5F5'}
            textColor={'#000000'}
            extend={{
                mainContainer: () => ({
                    tabletMax: {
                        height: 'auto',
                    },
                }),
                container: () => ({
                    tabletMax: {
                        height: 'auto',
                    },
                }),
                titleContainer: () => ({
                    tablet: {
                        marginTop: '10%',
                        paddingTop: 0,
                    },
                }),
            }}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: 'case.flashsport.section1.name',
                title: `case.flashsport.section1.title`,
                text: `case.flashsport.section1.text`,
                outputs: [
                    'references.case.output.android',
                    'references.case.output.ios',
                    'references.case.output.web',
                    'references.case.output.design',
                    'references.case.output.backend',
                    'references.case.output.testing',
                ],
            }}
        />
        <Gallery
            headerTheme="dark"
            fullHeight
            layout={GalleryLayout.ONE_HALF}
            content={[
                {
                    background: '#F5F4F5',
                    video: {
                        src: loadingVideo,
                        width: 1682,
                        height: 1682,
                    },
                },
                {
                    background: '#FF0436',
                    text: 'case.flashsport.galleryTitles.lightDarkMode',
                    textColor: '#FFFFFF',
                    image: {
                        src: 'references/case-studies/flashsport/light_dark.png',
                        style: {
                            height: '85%',
                            marginBottom: '4rem',
                        },
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                    },
                },
            ]}
        />
        <Divider height={'1.25rem'} />
        <TextContent
            headerTheme="dark"
            content={{
                section: 'case.flashsport.section2.name',
                title: `case.flashsport.section2.title`,
                text: `case.flashsport.section2.text`,
            }}
        />
        <Divider height={'1.25rem'} />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.ONE_THIRD}
            content={[
                {
                    background: '#ffffff',
                    image: {
                        src: 'references/case-studies/flashsport/soccer.jpg',
                        imgStyle: { objectFit: 'cover', objectPosition: 'center top' },
                    },
                },
                {
                    background: '#ffffff',
                    video: {
                        src: previewVideo,
                        width: 1120,
                        height: 920,
                    },
                },
            ]}
        />
        <Divider height={'1.25rem'} />
        <Carousel
            headerTheme="dark"
            deviceType={DeviceType.MOBILE}
            background="#F5F5F5"
            slides={[
                {
                    image: {
                        src: 'references/case-studies/flashsport/carousel/1.jpg',
                    },
                },
                {
                    image: {
                        src: 'references/case-studies/flashsport/carousel/2.jpg',
                    },
                },
                {
                    image: {
                        src: 'references/case-studies/flashsport/carousel/3.jpg',
                    },
                },
                {
                    image: {
                        src: 'references/case-studies/flashsport/carousel/4.jpg',
                    },
                },
                {
                    image: {
                        src: 'references/case-studies/flashsport/carousel/5.jpg',
                    },
                },
                {
                    image: {
                        src: 'references/case-studies/flashsport/carousel/6.jpg',
                    },
                },
                {
                    image: {
                        src: 'references/case-studies/flashsport/carousel/7.jpg',
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: 'case.flashsport.section3.name',
                title: `case.flashsport.section3.title`,
                text: `case.flashsport.section3.text`,
            }}
        />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.ONE_HALF}
            content={[
                {
                    background: '#FF0033',
                    textColor: '#FFFFFF',
                    text: 'case.flashsport.galleryTitles.webMobile',
                    image: {
                        style: {
                            height: '75%',
                            marginBottom: '4rem',
                        },
                        imgStyle: {
                            objectFit: 'contain',
                            objectPosition: 'center',
                        },
                        src: 'references/case-studies/flashsport/web-app.png',
                    },
                },
                {
                    background: '#FDE8EF',
                    video: {
                        src: notifyVideo,
                        width: 1440,
                        height: 1460,
                        style: { objectFit: 'contain', objectPosition: 'center bottom' },
                    },
                },
            ]}
        />
        <TextContent
            headerTheme="dark"
            content={{
                section: 'case.flashsport.section4.name',
                title: `case.flashsport.section4.title`,
                text: `case.flashsport.section4.text`,
            }}
        />
        <Divider height={'1.25rem'} />
        <Gallery
            headerTheme="light"
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: '#FF0033',
                    image: {
                        src: 'references/case-studies/flashsport/big-phone.png',
                        imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                    },
                },
            ]}
        />
        <Testimonial
            headerTheme="dark"
            background="#F9F9F9"
            content={{
                quote: 'case.flashsport.testimonial.text',
                person: {
                    name: 'case.flashsport.testimonial.name',
                    role: 'case.flashsport.testimonial.position',
                    image: {
                        src: 'references/case-studies/flashsport/testimonial.jpg',
                    },
                },
            }}
            type="PROFILE_RIGHT"
        />
        <Team headerTheme="dark" content={team} />
    </>
);

export default FlashSport;
